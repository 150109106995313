import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Layout, SEO } from 'components';
import styles from './styles/index.module.scss';
import PlaylistIndex from '../components/PlaylistIndex';

export const query = graphql`
  query pageQuery {
    allContentfulPlaylist {
      edges {
        node {
          ...Playlist
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => {
  const allPlaylists = data.allContentfulPlaylist.edges;

  return (
    <Layout isIndex={true}>
      <SEO title="TypeList" />
      <div className={styles.pageContentWrapper}>
        <PlaylistIndex allPlaylists={allPlaylists} />
      </div>
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.object,
};

export default IndexPage;
